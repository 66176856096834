import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <footer class="footer footer-bar">
        <div class="container text-center">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <div class="text-sm-left">
                <p class="mb-0">© 2020, Mail7</p>
              </div>
            </div>

            <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <ul class="list-unstyled terms-service text-sm-right mb-0">
                <li class="list-inline-item"><a onclick="amplitude.getInstance().logEvent('Roadmap');" href="https://trello.com/b/PYtMw65A/mail7-product-roadmap" target="_blank" class="text-foot mr-2">Project Roadmap</a></li>
                <li class="list-inline-item"><a onclick="amplitude.getInstance().logEvent('Bug Tracker');" href="https://github.com/Compile7/mail7-bug-tracker/issues" target="_blank" class="text-foot mr-2">Bug Tracker</a></li>
                <li class="list-inline-item"><a onclick="amplitude.getInstance().logEvent('Terms Page');" href="https://mail7.io/terms.html" target="_blank" class="text-foot mr-2">Terms</a></li>
                <li class="list-inline-item"><a onclick="amplitude.getInstance().logEvent('Privacy Page');" href="https://mail7.io/privacy.html" target="_blank" class="text-foot mr-2">Privacy</a></li>
                <li class="list-inline-item"><a onclick="amplitude.getInstance().logEvent('Contact Page');" href="https://mail7.io/contact.html" target="_blank" class="text-foot">Contact</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
