import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import Menu from '../Menu'
import logoPic from './logo.png'
import './style.scss'

class Header extends React.Component {
  render() {
    return (
      <header id="topnav" class="defaultscroll sticky active nav-sticky">
        <div class="container">
          <div>
            <a class="logo" href="/" onclick="amplitude.getInstance().logEvent('Home Page');"><img src={logoPic} Title="Mail7" /></a>
          </div>
          <div class="buy-button">
            <a href="https://console.mail7.io/admin/inbox/inbox" onclick="amplitude.getInstance().logEvent('Public Inbox');" class="btn btn-primary">Public Inbox</a>
            <a href="https://console.mail7.io/admin/inbox/private" onclick="amplitude.getInstance().logEvent('Private Inbox');" class="btn btn-light mr-2">Private Inbox</a>
          </div>
          <div class="menu-extras">
            <div class="menu-item">
              <a class="navbar-toggle" onclick="amplitude.getInstance().logEvent('Mobile Nav Click');">
                <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </div>
          </div>
          <div id="navigation" class="active">
            <ul class="navigation-menu">
              <li><a href="https://mail7.io/#usecases" onclick="amplitude.getInstance().logEvent('Use Cases');" target="_blank">Use Cases</a></li>
              <li class="last-elements"><a href="https://mail7.io/docs" onclick="amplitude.getInstance().logEvent('Docs Click');" target="_blank">Documents</a></li>
            </ul>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
